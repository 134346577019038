<template>
  <div class="BBS">
    <div class="BBS-box">
      <div class="BBS-box-content">
        <div class="title yichu1">vue-router实现路由变更监听</div>
        <div class="container">
          <div class="block">
            <el-avatar :size="50" :src="circleUrl"></el-avatar>
          </div>
          <div class="Odetail">
            <div class="outhor">维超智能</div>

            <div class="time">2022年3月4日16:30:33 字数:12123</div>
          </div>

        </div>
        <article class="baidu_pl">
          <div id="article_content" class="article_content clearfix">
            <link
              rel="stylesheet"
              href="https://csdnimg.cn/release/blogv2/dist/mdeditor/css/editerView/ck_htmledit_views-d7093e7f7c.css"
            />
            <div id="content_views" class="htmledit_views">
              <h2><a name="t0"></a><strong>用户点击链接元素：</strong></h2>
              <p>
                <strong
                  >户点击链接交互，即点击了<code
                    >&nbsp;&lt;router-link&gt;</code
                  ></strong
                >
              </p>
              <pre class="set-code-hide" name="code"> props: {
            tag: {
            type: String,
            default: 'a'
            },
        <span style="color:#86ca5e;">  // 绑定的事件</span>
            event: {
            type: eventTypes,
            default: 'click'
            }
        },
        render (h: Function) {
            const handler = e =&gt; {
            if (guardEvent(e)) {
                if (this.replace) {
                router.replace(location)
                } else {
                router.push(location)
                }
            }
            }

            const on = { click: guardEvent }
            if (Array.isArray(this.event)) {
            this.event.forEach(e =&gt; { on[e] = handler })
            } else {
            <span style="color:#86ca5e;"> // 事件绑定处理函数</span>
            on[this.event] = handler
            } 
            return h(this.tag, data, this.$slots.default)   
        }
        <div class="hide-preCode-box"><span class="hide-preCode-bt"><img class="look-more-preCode contentImg-no-view" src="https://csdnimg.cn/release/blogv2/dist/pc/img/newCodeMoreWhite.png" alt="" title=""></span></div></pre>
              <p>
                <strong
                  >该组件主要是通过<code>render</code>函数，默认创建一个<code>a</code>标签，同时为标签绑定<code>click</code>事件</strong
                >
              </p>
              <p>
                <strong
                  >在绑定事件的函数中，值得注意<code
                    >guardEvent执行操作：</code
                  ></strong
                >
              </p>
              <pre class="" name="code">function guardEvent (e) {
        <span style="color:#86ca5e;"> // 忽略带有功能键的点击</span>
        if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) return
        <span style="color:#86ca5e;">  // 调用preventDefault时不重定向</span>
        if (e.defaultPrevented) return
        <span style="color:#86ca5e;"> // 忽略右击</span>
        if (e.button !== undefined &amp;&amp; e.button !== 0) return
        <span style="color:#86ca5e;"> // 如果 `target="_blank"` 也不进行</span>
        if (e.currentTarget &amp;&amp; e.currentTarget.getAttribute) {
            const target = e.currentTarget.getAttribute('target')
            if (/\b_blank\b/i.test(target)) return
        }
        <span style="color:#86ca5e;">// 判断是否存在`e.preventDefault`，在 weex 中没有这个方法</span>
        if (e.preventDefault) {
            e.preventDefault()
        }
        return true
        }</pre>
              <p>
                <strong
                  >可以看到，这里主要对是否跳转进行了一些判断。再看看点击事件的处理函数：</strong
                >
              </p>
              <pre>
        const handler = e =&gt; {
        if (guardEvent(e)) {
            if (this.replace) {
            router.replace(location)
            } else {
            router.push(location)
            }
        }
        }</pre
              >
              <p>
                <strong
                  >可以看到其实他们只是代理而已，真正做事情的还是&nbsp;<code>history&nbsp;</code>来做：</strong
                >
              </p>
              <h3><a name="t1"></a><strong>浏览器本身的跳转动作</strong></h3>
              <p>
                <strong
                  >对于这种情况，先来看&nbsp;<code>hash</code>的方式，当发生变得时候会判断当前浏览器环境是否支持&nbsp;<code>supportsPushState&nbsp;</code>来选择监听&nbsp;<code>popstate</code>还是<code
                    >hashchange</code
                  ></strong
                >
              </p>
              <pre>
        window.addEventListener(supportsPushState ? 'popstate' : 'hashchange', () =&gt; {
        const current = this.current
        if (!ensureSlash()) {
            return
        }
        this.transitionTo(getHash(), route =&gt; {
            if (supportsScroll) {
            handleScroll(this.router, route, current, true)
            }
            if (!supportsPushState) {
            replaceHash(route.fullPath)
            }
        })
        })</pre
              >
              <p>
                <strong
                  >对应的<code>history</code>其实也是差不多。只不顾既然是<code>history</code>模式了，默认也就只用监听<code
                    >popstate</code
                  ></strong
                >
              </p>
              <pre>
        window.addEventListener('popstate', e =&gt; {
        const current = this.current
        const location = getLocation(this.base)
        if (this.current === START &amp;&amp; location === initLocation) {
            return
        }
        this.transitionTo(location, route =&gt; {
            if (supportsScroll) {
            handleScroll(router, route, current, true)
            }
        })
        })</pre
              >
            </div>
            <div><div></div></div>
          </div>
        </article>
        <div class="line-B"></div>
      </div>
      <div class="area-box">
        <!-- 富文本 -->
        <textarea
          class="_1u_H4i"
          @focus="areaFocus"
          ref="textarea"
          placeholder="写下你的评论..."
        ></textarea>
        <div v-if="show">
          <div class="_3IXP9Q">
            <div class="SKZUyR">
              <span>Ctrl + Enter 发表</span>
            </div>
            <div class="_3Tp4of">
              <button type="button" class="_1OyPqC _3Mi9q9 _1YbC5u">
                <span>发布</span></button
              ><button type="button" class="_1OyPqC _2nzlC_">
                <span>取消</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- 评论头部 -->
      <h3 class="QxT4hD">
        <div class="_10KzV0">
          <span>全部评论</span><span class="_2R7vBo">1</span
          ><span class="_1DVmvZ">只看作者</span>
        </div>
        <div class="_2zSaYx">
          <div class="_1ekjko _1BIpxf" role="button" tabindex="-1">
            按时间倒序
          </div>
          <div class="_1ekjko" role="button" tabindex="-1">按时间正序</div>
        </div>
      </h3>
      <!-- 评论详情 -->
      <div class="container comments">
        <div class="block">
          <el-avatar :size="50" :src="circleUrl"></el-avatar>
        </div>
        <div class="Odetail">
          <div class="Odetail-content" style="display: flex">
            <div class="outhor">维超智能</div>
            <div class="time commentsTime">2022年3月4日16:30:33 字数:12123</div>
          </div>
          <div class="commentsDetail">okok，学废了</div>
          <mate> </mate>
          <div class="line-B"></div>
          <div class="commentsAgain">
            <div class="block">
              <el-avatar :size="50" :src="circleUrl"></el-avatar>
            </div>
            <div class="Odetail">
              <div class="Odetail-content">
                <div class="outhor">维超智能</div>
                <div class="time commentsTime">
                  2022年3月4日16:30:33 字数:12123
                </div>
              </div>

              <div class="commentsDetail">okok，学废了</div>
              <mate> </mate>
              <div class="line-B"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container comments">
        <div class="block">
          <el-avatar :size="50" :src="circleUrl"></el-avatar>
        </div>
        <div class="Odetail">
          <div class="Odetail-content">
            <div class="outhor">维超智能</div>

            <div class="time commentsTime">2022年3月4日16:30:33 字数:12123</div>
          </div>

          <div class="commentsDetail">okok，学废了</div>
          <mate> </mate>
          <div class="line-B"></div>
          <div class="commentsAgain">
            <div class="block">
              <el-avatar :size="50" :src="circleUrl"></el-avatar>
            </div>
            <div class="Odetail">
              <div class="Odetail-content" >
                <div class="outhor">维超智能</div>

                <div class="time commentsTime">
                  2022年3月4日16:30:33 字数:12123
                </div>
              </div>

              <div class="commentsDetail">okok，学废了</div>
              <mate> </mate>
              <div class="line-B"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import elAvatar from 'element-ui'
import mate from "../CommonConponent/BBSMeta/meta.vue";
export default {
  components: { mate },
  name: "BBSDetail",
  data() {
    return {
      circleUrl:
        "http://ww2.sinaimg.cn/large/7a8aed7bjw1ezgal5vpjfj20go0p0adq.jpg",
      show: false,
    };
  },
  methods: {
    areaFocus() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="less" scoped>
.BBS {
  background-color: white;
  height: 100%;
  height: 100%;
  .title {
    font-size: 28px;
    word-wrap: break-word;
    color: #222226;
    font-weight: 600;
    margin: 20px 0;
    word-break: break-all;
  }
  .comments {
    // padding: 20px 0;
    .commentsAgain {
      display: flex;
    }
  }
  .commentsDetail {
    // margin-top: 20px;
    color: #222226;
  }

  ._3IXP9Q {
    justify-content: space-between;
    margin-top: 16px;
    font-size: 14px;
    color: #969696;
    align-content: center;
    display: flex;
  }
  ._3Tp4of {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  ._1OyPqC {
    position: relative;
    flex-shrink: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 14px;
    padding: 4px 12px;
    color: #969696;
    background-color: #fff;
    border: 1px solid #999;
  }
  ._3Mi9q9._1YbC5u,
  ._3Mi9q9._1YbC5u[disabled]:hover {
    color: #fff;
    background-color: #ec7259;
    border-color: #ec7259;
  }
  ._1OyPqC + ._1OyPqC {
    margin-left: 8px;
  }
  .container {
    display: flex;
    margin-bottom: 10px;

    .Odetail {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 20px;
      color: #666;
      flex: 1;
      .Odetail-content {
        display: flex;
        margin-bottom: 15px;
      }
      .outhor {
        color: #666;
        font-size: 14px;
      }
      .commentsTime {
        color: #999;
        font-size: 13px;
        margin-left: 20px;
      }
    }
  }
  .BBS-box {
    max-width: 992px;
    margin: 0 auto;
    padding: 10px 20px;
    background: #ffffff;

    .BBS-box-content {
    }
    .area-box {
      // padding-bottom: 30px;
    }
    ._1u_H4i {
      padding: 12px 16px;
      width: 100%;
      height: 90px;
      font-size: 13px;
      border: 1px solid #eee;
      border-radius: 4px;
      background-color: #fafafa;
      resize: none;
      display: inline-block;
      vertical-align: top;
      outline-style: none;
      overflow: auto;
    }
    .QxT4hD {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      padding-left: 12px;
      border-left: 4px solid #ec7259;
      font-size: 18px;
      font-weight: 500;
      height: 20px;
      line-height: 20px;
      margin-top: 20px;
      ._2R7vBo {
        margin-left: 6px;
        font-size: 14px;
        font-weight: normal;
      }
      ._1DVmvZ {
        font-size: 12px;
        font-weight: normal;
        color: #969696;
        margin-left: 12px;
        padding: 2px 8px;
        border: 1px solid #eee;
        border-radius: 16px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      ._2zSaYx {
        display: flex;
      }
      ._1ekjko,
      ._393S4n {
        font-size: 14px;
        font-weight: normal;
        color: #969696;
        margin-left: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      ._1BIpxf {
        color: #2d2d2d;
      }
    }
  }
}
</style>